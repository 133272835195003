<template>
  <v-card>
    <v-card-title class="headline">Racer Rewards Rules &amp; FAQs</v-card-title>
    <v-card-text>
      <ContentView contentKey="RACER_RULES_AND_FAQS" />
    </v-card-text>
  </v-card>
</template>

<script>
import ContentView from "../../gapp-components/components/common/content/ContentView.vue";
export default {
  name: "RacerRulesAndFaqs",
  metaInfo: {
    title: "Racer Rules and FAQs"
  },
  components: { ContentView }
};
</script>
